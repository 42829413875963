export const messages2024 = {
  title:
    "Nowości technologiczne ROOVEE w 2024 roku – napędzamy przyszłość polskiego rynku rowerowego!",
  descriptionTop:
    "Rok 2024 był dla ROOVEE kolejnym krokiem milowym w naszej misji, aby dostarczać najlepsze rozwiązania dla miejskiego transportu rowerowego. Jako duma polskiej myśli technologicznej, nieustannie rozwijamy się, wprowadzając innowacje, które stawiają nas w czołówce branży. Sprawdźcie, co nowego przygotowaliśmy w 2024 roku!",
  descriptionBottom:
    "ROOVEE to więcej niż wypożyczalnia rowerów – to polska firma, która redefiniuje miejski transport. Nieustannie się rozwijamy, łącząc technologię i pasję, by zmieniać miasta na lepsze. Dziękujemy, że jesteście z nami i zapraszamy do śledzenia naszych dalszych kroków!",
  cards: [
    {
      title: "Mapa ciepła tras – widzimy ruch w nowym świetle",
      description:
        "Nasza zaktualizowana mapa ciepła pozwala na wizualizację i analizę rzeczywistych przebytych tras przejazdów. To nie tylko technologia – to narzędzie dla lepszej przyszłości infrastruktury rowerowej. ROOVEE pomaga miastom planować z myślą o ludziach.",
      messages: [
        {
          title:
            "Rozlokowanie stref parkowania zgodne z realnym zapotrzebowaniem.",
        },
        {
          title: "Wyraźnie wskazanie najpopularniejszych tras.",
        },
        {
          title: "Podstawy do tworzenia nowoczesnych ścieżek rowerowych.",
        },
        {
          title: "Większa wygoda i bezpieczeństwo dla użytkowników.",
        },
      ],
    },
    {
      title: "Rozszerzone możliwości raportowania – dane na wyciągnięcie ręki",
      description:
        "ROOVEE udowadnia, że szczegółowa analiza danych to klucz do efektywności. W 2024 roku wprowadziliśmy nowe raporty danych, pozwalające na jeszcze dokładniejsze analizy, monitorowanie i zarządzanie flotą. Dane, które udostępniamy, pomagają miastom lepiej planować rozwój transportu miejskiego.",
      messages: [
        {
          title: "Precyzyjna analiza zachowań użytkowników.",
        },
        {
          title: "Optymalizacja zarządzania flotą rowerową.",
        },
        {
          title: "Wsparcie miast w strategicznych decyzjach inwestycyjnych.",
        },
      ],
    },
    {
      title:
        "Automatyzacja raportowania – inteligentne narzędzia dla Zamawiających",
      description:
        "Wprowadziliśmy opcję automatycznego generowania raportów w ustalonym dniu. Dzięki temu zarządzanie flotą nigdy nie było prostsze.",
      messages: [
        {
          title: "Oszczędność czasu.",
        },
        {
          title: "Pełna automatyzacja procesu raportowania.",
        },
        {
          title: "Ciągły dostęp do najświeższych danych.",
        },
      ],
    },
    {
      title: "Obsługa usterek dla e-rowerów – zawsze o krok do przodu",
      description:
        "Rozbudowaliśmy system zgłoszeń usterek, uwzględniając specyficzne potrzeby rowerów z elektrycznym wspomaganiem. Dzięki temu nasze zaawansowane technologicznie rowery są jeszcze bardziej niezawodne.",
      messages: [
        {
          title: "Szybsze i bardziej precyzyjne naprawy.",
        },
        {
          title: "Dłuższa żywotność floty.",
        },
        {
          title: "Zadowolenie użytkowników, którzy zawsze mogą na nas polegać.",
        },
      ],
    },
    {
      title: "Abonamenty z limitem minut – na miarę Twoich potrzeb",
      description:
        "Dla tych, którzy korzystają z rowerów okazjonalnie, stworzyliśmy abonamenty z limitem minut. Teraz każdy znajdzie coś dla siebie.",
      messages: [
        {
          title: "Elastyczne opcje abonamentowe.",
        },
        {
          title: "Niższe koszty dla użytkowników korzystających rzadziej.",
        },
        {
          title: "Oferta dopasowana do stylu życia.",
        },
        {
          title:
            "Here is a title describing a major update regarding an ongoing project.",
        },
      ],
    },
    {
      title: "Bezpieczeństwo przede wszystkim – 2FA i automatyczne wylogowanie",
      description:
        "Rozumiemy, jak ważne jest bezpieczeństwo w sieci. Dlatego wprowadziliśmy dwustopniowe uwierzytelnianie (2FA) oraz system automatycznego wylogowania w przypadku przerwania sesji.",
      messages: [
        {
          title: "Większa ochrona danych osobowych.",
        },
        {
          title: "Redukcja ryzyka naruszeń bezpieczeństwa.",
        },
        {
          title: "Pełne zaufanie do systemów ROOVEE.",
        },
      ],
    },
    {
      title: "Ulepszona obsługa klienta – wygoda przede wszystkim",
      description:
        "Usprawniliśmy proces zmiany adresu e-mail oraz wprowadziliśmy nowy system korekt, aby szybciej i skuteczniej reagować na potrzeby naszych użytkowników.",
      messages: [
        {
          title: "Większa satysfakcja klienta.",
        },
        {
          title: "Szybsze rozwiązywanie problemów.",
        },
        {
          title: "Uproszczone procesy obsługi.",
        },
      ],
    },
    {
      title: "Nowa aplikacja mobilna – przyszłość na wyciągnięcie ręki",
      description:
        "Nasza nowa aplikacja to nie tylko narzędzie – to całkowicie nowe doświadczenie. Intuicyjny interfejs i nowoczesny design sprawiają, że korzystanie z ROOVEE to czysta przyjemność.",
      messages: [
        {
          title: "Jeszcze prostszy dostęp do kluczowych funkcji.",
        },
        {
          title: "Nowoczesny wygląd i płynne działanie.",
        },
        {
          title: "Komfort na każdym etapie użytkowania.",
        },
      ],
    },
  ],
};
