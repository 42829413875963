import React from "react";
import { messages2024 } from "./MessagesList";
import WhatsNew from "./WhatsNew";

const WhatsNewPage = () => {
  return (
    <div className="page padder">
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <WhatsNew messages={messages2024} />
        </div>
      </div>
    </div>
  );
};

export default WhatsNewPage;
