import React from "react";
import PropTypes from "prop-types";
import WhatsNewCard from "./WhatsNewCard";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  wrapper: {
    padding: "10px 15px",
    [theme.breakpoints.up("md")]: {
      width: 700,
    },
  },
}));

const WhatsNew = ({ messages }) => {
  const classes = useStyles();

  return (
    <div className={classes.center} style={{ marginBottom: 50 }}>
      <div className={classes.wrapper}>
        <h1 style={{ marginBottom: 15 }}>{messages.title}</h1>
        <div style={{ fontSize: 16, marginBottom: 15, textAlign: "justify" }}>
          {messages.descriptionTop}
        </div>
      </div>
      <div className={classes.center}>
        {messages.cards.map((x) => (
          <WhatsNewCard
            key={`whatsNewPage_${x.title}`}
            title={x.title}
            description={x.description}
            messages={x.messages}
          />
        ))}
      </div>
      <div className={classes.wrapper}>
        <div style={{ fontSize: 16, fontWeight: "bold", textAlign: "justify" }}>
          {messages.descriptionBottom}
        </div>
      </div>
    </div>
  );
};

WhatsNew.propTypes = {
  messages: PropTypes.objectOf({}).isRequired,
};
export default WhatsNew;
