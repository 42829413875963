import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "10px 15px",
    [theme.breakpoints.up("md")]: {
      width: 700,
    },
    marginBottom: 25,
    borderRadius: 15,
  },
}));

const WhatsNewCard = ({ title, description, messages }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.card}>
      <h2>{`${title}`}</h2>
      <div style={{ textAlign: "justify" }}>{description}</div>
      <div style={{ marginTop: 15 }}>
        <div style={{ fontWeight: "bold" }}>Korzyści:</div>
        <ul>
          {messages.map((x) => (
            <li key={`${x.title}`}>
              <div
                style={{ fontWeight: "bold", lineHeight: 1.8, fontSize: 14 }}
              >
                {x.title}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Paper>
  );
};

WhatsNewCard.propTypes = {
  title: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  description: PropTypes.string.isRequired,
};

export default WhatsNewCard;
