import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment-duration-format";
import _ from "lodash";
import ReportTable from "../../common/reportTable/ReportTable";
import { actions } from "../../../reducers/reports/rideDetails/actions";
import { actions as relocationActions } from "../../../actions/relocationActions";

import numberFormatter from "../../../utils/numberFormatter";
import durationFormatter from "../../../utils/durationFormatter";
import MapTraceView from "./MapTraceView";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import PauseIcon from "@material-ui/icons/PauseCircleOutline";
import MapTraceMarker, {
  finishTraceIcon,
  startTraceIcon,
} from "./MapTraceMarker";
import ErrorOutlineIcon from "@material-ui/icons/Error";
import { columns } from "./Columns";
import "./styles.scss";
import FareDetails from "./FareDetails";
import routePaths from "../../../routePaths";
import FareIssueDetails from "./FareIssueDetails";
import { FareIssueStatus } from "../../../reducers/reports/rides/models";
import { hasPermission, permissions } from "../../../utils/authorization";
import FinishRideDetails from "./FinishRideDetails";
import RestartRideDetails from "./RestartRideDetails";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core";
import { FinishRideReasonType } from "../../../models/FinishRideReasonType";

function ExpandMoreIcon() {
  return null;
}

class RideDetailsReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gpxExportInProgress: false,
      gpsPositionExportInProgress: false,
      validTelemetry: [],
      showRideHistory: false,
      pagination: {
        pageSize: 100,
        pageNumber: 1,
        totalPages: null,
        totalSize: null,
      },
      listData: [],
    };

    this.handleRideRestart = this.handleRideRestart.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleExportGPSPosition = this.handleExportGPSPosition.bind(this);
    this.parseBikePhotoDate = this.parseBikePhotoDate.bind(this);
  }

  componentDidMount() {
    this.props.actions.loadRideDetailsReport(this.props.rideId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.telemetry.length !== this.props.telemetry.length ||
      prevState.pagination.pageSize !== this.state.pagination.pageSize
    ) {
      this.setState({
        pagination: {
          ...this.state.pagination,
          totalPages: Math.ceil(
            this.props.telemetry.length / this.state.pagination.pageSize,
          ),
          totalSize: this.props.telemetry.length,
        },
      });
    }

    if (prevState.pagination !== this.state.pagination) {
      if (this.props.telemetry) {
        this.setState({
          listData: this.props.telemetry.slice(
            (this.state.pagination.pageNumber - 1) *
              this.state.pagination.pageSize,
            this.state.pagination.pageNumber * this.state.pagination.pageSize,
          ),
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      validTelemetry: nextProps.telemetry.filter(
        (t) => !(t.latitude === 0 && t.longitude === 0),
      ),
    });
  }

  onShowRideHistory() {
    this.setState({ showRideHistory: true });
  }

  handlePageSizeChange(value) {
    this.setState({
      pagination: {
        ...this.state.pagination,
        pageSize: value,
        pageNumber: 1,
      },
    });
  }

  handlePageChange(value) {
    this.setState({
      pagination: {
        ...this.state.pagination,
        pageNumber: value,
      },
    });
  }

  handleRideRestart() {
    this.props.actions.restartRide(this.props.rideId);
  }

  getMarkers() {
    return [
      { ..._.first(this.state.validTelemetry), isStartMarker: true },
      { ..._.last(this.state.validTelemetry), isFinishMarker: true },
    ];
  }

  handleExportToGpx() {
    this.setState({ gpxExportInProgress: true });
    this.props.reportActions
      .requestGpxExport(this.props.rideId)
      .finally(() => this.setState({ gpxExportInProgress: false }));
  }

  handleExportGPSPosition() {
    this.setState({ gpsPositionExportInProgress: true });
    this.props.reportActions
      .requestGPSPosition(this.props.rideId)
      .finally(() => this.setState({ gpsPositionExportInProgress: false }));
  }

  renderExportToGpx() {
    const { gpxExportInProgress } = this.state;
    return (
      <Tooltip title="Eksportuj do formatu gpx" placement="right-start">
        <Button
          disabled={gpxExportInProgress}
          variant="contained"
          color="primary"
          onClick={() => this.handleExportToGpx()}
        >
          Eksportuj do GPX
          {gpxExportInProgress && <CircularProgress size={24} />}
        </Button>
      </Tooltip>
    );
  }

  parseBikePhotoDate(date) {
    const parsedDate = moment(date);
    return parsedDate.format("YYYY-MM-DD HH:mm:ss");
  }

  render() {
    const { summary, zones } = this.props;
    return (
      <div className="page">
        {this.props.loading ? (
          <div className="progress">
            <CircularProgress size={50} />
          </div>
        ) : (
          <div style={{ margin: 40 }}>
            <Typography variant="subtitle1" gutterBottom>
              Szczegóły przejazdu
            </Typography>
            <Paper style={{ padding: 16, marginBottom: 16 }}>
              <MapTraceView
                loadingElement={<div style={{ height: "100%", width: 500 }} />}
                containerElement={
                  <div style={{ width: "100%", height: 400 }} />
                }
                mapElement={<div style={{ width: "100%", height: 400 }} />}
                onMapLoad={(ref) => (this.mapRef = ref)}
                onMapClick={() => {}}
                markers={this.getMarkers()}
                onMarkerRightClick={() => {}}
                markerElement={(props) =>
                  props.location ? <MapTraceMarker {...props} /> : null
                }
                initialLocation={this.props.initialLocation}
                initialZoom={this.props.initialZoom}
                zones={this.props.zones}
                trace={this.state.validTelemetry.map((t) => t.location)}
                attemptLocation={summary.attemptLocation}
              />
              <div className="flex-row flex-wrap">
                {summary.startedAt && (
                  <React.Fragment>
                    <div className="panel-right flex-column">
                      <h3>Przejazd</h3>
                      <div className="flex-row flex-grow trace-summary">
                        <div className="flex-column margin-right-small align-items-center">
                          <SvgIcon>
                            <path d={startTraceIcon.path} />
                          </SvgIcon>
                          <div className="flex trace-summary-separator" />
                          {summary.finishedAt && (
                            <SvgIcon>
                              <path d={finishTraceIcon.path} />
                            </SvgIcon>
                          )}
                          {summary.ongoingPause && (
                            <Tooltip
                              title={moment(summary.ongoingPauseDate)
                                .local()
                                .format("DD.MM.YYYY HH:mm:ss")}
                            >
                              <PauseIcon />
                            </Tooltip>
                          )}
                        </div>
                        <div className="flex-column justify-space-between margin-right-small">
                          <div className="margin-bottom-large">
                            <Typography color="textSecondary">Start</Typography>
                            <Typography>
                              {moment(summary.startedAt)
                                .local()
                                .format("HH:mm:ss")}
                            </Typography>
                            <Typography>
                              {moment(summary.startedAt)
                                .local()
                                .format("DD.MM.YYYY")}
                            </Typography>
                          </div>
                          {summary.finishedAt ? (
                            <div>
                              <Typography color="textSecondary">
                                Koniec
                              </Typography>
                              <Typography>
                                {moment(summary.finishedAt)
                                  .local()
                                  .format("HH:mm:ss")}
                              </Typography>
                              <Typography>
                                {moment(summary.finishedAt)
                                  .local()
                                  .format("DD.MM.YYYY")}
                              </Typography>
                              {hasPermission(
                                this.props.userPermissions,
                                permissions.restartRide,
                              ) &&
                                new Date().getTime() -
                                  new Date(summary.finishedAt).getTime() <
                                  108e5 &&
                                summary.manualRestartInfo == null && (
                                  <RestartRideDetails
                                    summary={summary}
                                    currentUser={this.props.currentUser}
                                  />
                                )}
                            </div>
                          ) : (
                            <div>
                              {hasPermission(
                                this.props.userPermissions,
                                permissions.finishRide,
                              ) && (
                                <FinishRideDetails
                                  summary={summary}
                                  suggestedFinishTime={
                                    summary.suggestedFinishTime
                                  }
                                  suggestedFinishLocation={
                                    summary.suggestedFinishLocation
                                  }
                                  zones={zones}
                                  currentUser={this.props.currentUser}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {summary.finishedAt &&
                        hasPermission(
                          this.props.userPermissions,
                          permissions.restartRide,
                        ) && (
                          <div className="trace-summary-export-gpx">
                            {this.renderExportToGpx()}
                          </div>
                        )}
                    </div>
                    <div className="panel-right">
                      <h3 className="trace-summary-header">Przejazd</h3>
                      <div>
                        <Typography color="textSecondary">Czas</Typography>
                        <Typography>
                          {durationFormatter.format(summary.rideDuration)}
                        </Typography>
                        <Typography color="textSecondary">Dystans</Typography>
                        <Typography className="value">
                          {numberFormatter.format(summary.traceLength)} km
                        </Typography>
                        <Typography color="textSecondary">
                          Średnia prędkość
                        </Typography>
                        <Typography>
                          {numberFormatter.format(summary.averageSpeed)} km/h
                        </Typography>
                        <Typography color="textSecondary">
                          Ocena przejazdu
                        </Typography>
                        <Typography>
                          {summary.rating != null
                            ? `${summary.rating}/5`
                            : "Nie oceniono"}
                        </Typography>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className="panel-right">
                  <h3>Rower</h3>
                  <div>
                    <Typography color="textSecondary">Numer roweru</Typography>
                    <Typography className="value">
                      {summary.bikeNumber}
                    </Typography>
                    <Typography color="textSecondary">Zamek</Typography>
                    <Typography>{summary.bikeLockImei}</Typography>
                  </div>
                </div>
                {hasPermission(
                  this.props.userPermissions,
                  permissions.clients,
                ) && (
                  <div className="panel-right">
                    <h3>Użytkownik</h3>
                    <div>
                      <Typography color="textSecondary">
                        Imię i nazwisko
                      </Typography>
                      <Typography className="value">
                        <Link
                          to={`${routePaths.clientsReport}/${summary.userId}/history`}
                        >
                          {summary.userFullName}
                        </Link>
                      </Typography>
                      <Typography color="textSecondary">Telefon</Typography>
                      <Typography className="value">
                        {summary.userPhoneNumber}
                      </Typography>
                      <Typography color="textSecondary">Email</Typography>
                      <Typography>{summary.userEmail}</Typography>
                      {summary.userIsDeleted && (
                        <Typography variant="caption" color="error">
                          Konto usunięte
                        </Typography>
                      )}
                    </div>
                  </div>
                )}
                {summary.pricingCalculation && (
                  <div className="panel-right">
                    <h3>
                      Płatność{" "}
                      {summary.outstandingPayment > 0 && (
                        <ErrorOutlineIcon
                          className="errorIcon"
                          label="Nieopłacone"
                        />
                      )}
                    </h3>
                    <div>
                      <Typography color="textSecondary">Rachunek</Typography>
                      <Typography className="value amount">
                        {numberFormatter.format(summary.finalFare)} zł
                      </Typography>
                      <Typography color="textSecondary">
                        Pozostało do zapłaty
                      </Typography>
                      <Typography className="amount">
                        {numberFormatter.format(summary.outstandingPayment)} zł
                      </Typography>
                    </div>
                  </div>
                )}
                {summary.operatorId ||
                summary.manualFinishInfo ||
                summary.manualRestartInfo ? (
                  <div className="panel-right">
                    <h3>Inne</h3>
                    <div>
                      {summary.operatorId && (
                        <React.Fragment>
                          <Typography color="textSecondary">
                            Przejazd rozpoczęty przez operatora
                          </Typography>
                          <Typography className="value">
                            {summary.operatorFullName}
                          </Typography>
                        </React.Fragment>
                      )}
                      {summary.manualRestartInfo && (
                        <React.Fragment>
                          <Typography color="textSecondary">
                            Przejazd wznowiony przez operatora
                          </Typography>
                          <Typography className="value">
                            {summary.manualRestartInfo.restartedBy.firstName}{" "}
                            {summary.manualRestartInfo.restartedBy.lastName}
                          </Typography>
                        </React.Fragment>
                      )}
                      {summary.manualFinishInfo && (
                        <React.Fragment>
                          <Typography color="textSecondary">
                            Przejazd zakończony przez operatora
                          </Typography>
                          <Typography className="value">
                            {summary.manualFinishInfo.finishedBy.firstName}{" "}
                            {summary.manualFinishInfo.finishedBy.lastName}
                          </Typography>
                          <Typography color="textSecondary">
                            Komentarz
                          </Typography>
                          <Typography className="value">
                            {summary.manualFinishInfo.comments}
                          </Typography>
                          <Typography color="textSecondary">
                            Powód zakończenia
                          </Typography>
                          <Typography className="value">
                            {summary.manualFinishInfo.finishRideReasonType
                              ? FinishRideReasonType[
                                  summary.manualFinishInfo.finishRideReasonType
                                ].name
                              : "Brak informacji"}
                          </Typography>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </Paper>

            {summary.pricingCalculation && (
              <React.Fragment>
                <Typography variant="subtitle1" gutterBottom>
                  Naliczenia
                </Typography>
                <Paper style={{ padding: 16, marginBottom: 16 }}>
                  <FareDetails rideDetails={summary} zones={zones} />
                </Paper>
              </React.Fragment>
            )}

            {summary.fareIssue && (
              <React.Fragment>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  color={
                    summary.fareIssue.status === FareIssueStatus.opened.value
                      ? "error"
                      : "default"
                  }
                >
                  Reklamacja
                </Typography>
                <Paper style={{ padding: 16, marginBottom: 16 }}>
                  <FareIssueDetails
                    authorized={hasPermission(
                      this.props.userPermissions,
                      permissions.resolveFareIssue,
                    )}
                    details={summary.fareIssue}
                    resolveFareIssue={(data) =>
                      this.props.actions.resolveFareIssue(
                        summary.rideId,
                        data,
                        this.props.currentUser,
                      )
                    }
                  />
                </Paper>
              </React.Fragment>
            )}
            <Typography variant="subtitle1" gutterBottom>
              Zdjęcie roweru po przejeździe
            </Typography>
            <Paper style={{ padding: 16, marginBottom: 30 }}>
              {summary.bikePhotoUrl ? (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Pokaż zdjęcie</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      flexDirection: "column",
                      borderTop: "1px solid #e2e2e2",
                    }}
                  >
                    <div style={{ maxWidth: 800 }}>
                      <div>
                        {summary.bikePhotoDate ? (
                          <div>
                            {`Data wykonania zdjęcia: ${this.parseBikePhotoDate(summary.bikePhotoDate)}`}
                          </div>
                        ) : (
                          <div>Brak daty wykonania zdjęcia</div>
                        )}
                        {summary.bikePhotoLocation ? (
                          <div>
                            {`Lokalizacja: ${summary.bikePhotoLocation.lat} ${summary.bikePhotoLocation.lng}`}
                          </div>
                        ) : (
                          <div>Brak danych o lokalizacji zdjęcia</div>
                        )}
                      </div>
                      <div style={{ marginTop: 5 }}>
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a href={summary.bikePhotoUrl} target="_blank">
                          Otwórz w pełnym rozmiarze
                        </a>
                      </div>
                      <img
                        src={summary.bikePhotoUrl}
                        alt="Zdjęcie roweru po przejeździe"
                        width="100%"
                        style={{ marginTop: 5 }}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <h4>Brak zdjęcia</h4>
              )}
            </Paper>
            <div style={{ paddingBottom: 20 }}>
              <Typography variant="subtitle1" gutterBottom>
                Historia przejazdu
                {!this.state.showRideHistory && (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: 15,
                      fontSize: 13,
                      padding: "5px 10px",
                    }}
                    onClick={() => this.onShowRideHistory()}
                  >
                    Pokaż historię przejazdu
                  </Button>
                )}
                <Button
                  disabled={this.state.gpsPositionExportInProgress}
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 15, fontSize: 13, padding: "5px 10px" }}
                  onClick={() => this.handleExportGPSPosition()}
                >
                  Pobierz historie GPS
                </Button>
              </Typography>
              {this.state.showRideHistory && (
                <Paper style={{ padding: 16 }}>
                  <ReportTable
                    style={{ height: "calc(100vh - 300px)" }}
                    columns={columns}
                    data={this.state.listData}
                    filterable={false}
                    sortable={false}
                    showPagination={true}
                    showPageJump={true}
                    loading={this.props.loading}
                    paging={this.state.pagination}
                    onPageSizeChange={this.handlePageSizeChange}
                    onPageChange={this.handlePageChange}
                  />
                </Paper>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

RideDetailsReportPage.propTypes = {
  actions: PropTypes.object.isRequired,
  reportActions: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  summary: PropTypes.object,
  telemetry: PropTypes.array,
  rideId: PropTypes.string.isRequired,
  zones: PropTypes.array.isRequired,
  initialLocation: PropTypes.object.isRequired,
  initialZoom: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  userPermissions: PropTypes.array.isRequired,
};

RideDetailsReportPage.defaultProps = {
  loading: false,
  summary: {},
  telemetry: [],
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    reportActions: bindActionCreators(relocationActions, dispatch),
  };
}

function mapStateToProps(state, ownProps) {
  const reportState = state.reports.rideDetails;
  return {
    rideId: ownProps.match.params.id,
    summary: reportState.summary,
    telemetry: reportState.telemetry,
    loading: reportState.loading,
    zones: state.zones,
    initialLocation: state.configuration.initialLocation,
    initialZoom: state.configuration.initialZoom,
    currentUser: state.auth,
    userPermissions: state.auth.permissions,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RideDetailsReportPage);
